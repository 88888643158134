import { graphql } from 'gatsby';
import React from 'react';
import Page from '.';
import { PageDataType } from '../../types/Page';

interface Props {
  data: PageDataType;
}

const PageTemplate: React.StatelessComponent<Props> = ({ data }) => <Page {...data} />;

export default PageTemplate;

export const query = graphql`
  query($wordpress_id: Int!) {
    headerMenu: wordpressWpApiMenusMenusItems(name: { eq: "header" }) {
      items {
        ...MenuItem
      }
    }
    mainMenu: wordpressWpApiMenusMenusItems(name: { eq: "main" }) {
      items {
        ...MenuItem
      }
    }
    secondaryFooterMenu: wordpressWpApiMenusMenusItems(name: { eq: "secondary-footer" }) {
      items {
        ...MenuItem
      }
    }
    footerTopPartners: allWordpressSitgPartnersTop {
      edges {
        node {
          ...TopPartner
        }
      }
    }
    footerSecondaryPartners: allWordpressSitgPartnersSecondary {
      edges {
        node {
          ...SecondaryPartner
        }
      }
    }
    announcement: wordpressSitgAnnouncements {
      ...Announcement
    }
    content: wordpressSitgPages(wordpress_id: { eq: $wordpress_id }) {
      ...Page
    }
  }
`;
